var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dl-databrowser" },
    [
      _c(
        "div",
        {
          staticClass: "left-content",
          style: { width: _vm.isExpand ? "300px" : "0px" },
        },
        [
          _vm.leftHeight > 0
            ? _c("div", { style: { height: _vm.leftHeight + "px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "card",
                    style: { height: _vm.topHeight + "px" },
                  },
                  [
                    _c("p", { staticClass: "card-top-title" }, [
                      _vm._v("待选数据集 "),
                      _vm.currentTemplateNode.templateName
                        ? _c(
                            "span",
                            {
                              staticClass: "selected-name",
                              attrs: {
                                title: _vm.currentTemplateNode.templateName,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "" + _vm.currentTemplateNode.templateName
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1", height: "0" } },
                      [
                        _c("TreeComponent", {
                          ref: "templateTree",
                          staticStyle: { padding: "12px 10px 10px 10px" },
                          attrs: {
                            showFilterInput: false,
                            treeData: _vm.businesstemplateData,
                            defaultExpandAll: "",
                            defaultProps: {
                              children: "children",
                              label: "templateName",
                            },
                          },
                          on: { "node-click": _vm.templateNodeClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var data = ref.data
                                  return _c(
                                    "span",
                                    { staticClass: "node-label" },
                                    [
                                      data.children && data.children.length
                                        ? _c(
                                            "span",
                                            { staticClass: "parent-node" },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "category",
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.templateName) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "span",
                                            {
                                              attrs: {
                                                title: data.templateName,
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "data-table",
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.templateName) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            3629566659
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", {
                  staticClass: "resizer",
                  on: { mousedown: _vm.startResize },
                }),
                _c(
                  "div",
                  {
                    staticClass: "card",
                    style: { height: _vm.bottomHeight + "px" },
                  },
                  [
                    _c("p", { staticClass: "card-top-title" }, [
                      _vm._v("待选范围 "),
                      _vm.currentNode.scopeName
                        ? _c(
                            "span",
                            {
                              staticClass: "selected-name",
                              attrs: { title: _vm.currentNode.scopeName },
                            },
                            [_vm._v(_vm._s("" + _vm.currentNode.scopeName))]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          margin: "20px 0 12px 20px",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              size: "small",
                              multiple: "",
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              "collapse-tags": "",
                              clearable: "",
                              placeholder: "请输入股票代码,如:000001",
                              "popper-class": "secCode-select-popper",
                              "remote-method": _vm.remoteMethod,
                              loading: _vm.loading,
                            },
                            on: { change: _vm.selectedSeccodeChange },
                            model: {
                              value: _vm.queryParams.selectedSeccode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "selectedSeccode",
                                  $$v
                                )
                              },
                              expression: "queryParams.selectedSeccode",
                            },
                          },
                          _vm._l(_vm.secCodeOptions, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.seccode + item.isin,
                                attrs: {
                                  label: item.secname,
                                  value: item.seccode,
                                },
                              },
                              [
                                _c("div", { staticClass: "secCode-content" }, [
                                  _c("div", [_vm._v(_vm._s(item.seccode))]),
                                  _c("div", { staticClass: "secname" }, [
                                    _c("span", [_vm._v(_vm._s(item.secname))]),
                                    item.delistingDate
                                      ? _c(
                                          "span",
                                          { staticClass: "delisting" },
                                          [_vm._v("退")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                        _c("el-button", {
                          staticStyle: { "margin-left": "8px" },
                          attrs: { size: "small", icon: "el-icon-search" },
                          on: { click: _vm.handleSearch },
                        }),
                        _c(
                          "el-popover",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.queryParams.selectedSeccode.length > 1,
                                expression:
                                  "queryParams.selectedSeccode.length > 1",
                              },
                            ],
                            attrs: {
                              placement: "bottom",
                              trigger: "hover",
                              "popper-class": "seccode-popover",
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "popover-div",
                              style: {
                                left: _vm.popoverLeft + "px",
                                width: _vm.popoverWidth + "px",
                              },
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                            _c(
                              "div",
                              { staticClass: "seccode-tag" },
                              _vm._l(
                                _vm.queryParams.selectedSeccode.slice(1),
                                function (item) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: item,
                                      attrs: { closable: "" },
                                      on: {
                                        close: function ($event) {
                                          return _vm.deleteSeccode(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item) +
                                          " " +
                                          _vm._s(_vm.selectedSeccodeMap[item])
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { flex: "1", height: "0" } },
                      [
                        _vm.scopeTreeData.length
                          ? _c("TreeComponent", {
                              ref: "scopeTree",
                              staticStyle: { padding: "0px 10px 10px 10px" },
                              attrs: {
                                showFilterInput: false,
                                treeData: _vm.scopeTreeData,
                                expandOnClickNode: false,
                                defaultExpandedKeys: _vm.defaultExpandedKeys,
                                nodeKey: "id",
                                defaultProps: {
                                  children: "children",
                                  label: "scopeName",
                                },
                              },
                              on: { "node-click": _vm.scopeNodeClick },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var data = ref.data
                                      return _c(
                                        "span",
                                        { staticClass: "node-content" },
                                        [
                                          !data.paraCode
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "parent-node",
                                                  class: {
                                                    "disabled-label":
                                                      _vm.setDisabled(data),
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class": "row",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.scopeName) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "node-label",
                                                  class: {
                                                    "disabled-label":
                                                      _vm.setDisabled(data),
                                                  },
                                                  attrs: {
                                                    title: data.scopeName,
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "stock-market",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.scopeName) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                          data.id === "1" &&
                                          !_vm.checkPermi([
                                            "DataPlatform:DingLiApiV2:LimitAccess",
                                          ])
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus-outline add-icon",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.addSecCodeTemplate(
                                                      $event
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          data.parentId === "1"
                                            ? _c(
                                                "span",
                                                { staticClass: "node-btn" },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      type: "text",
                                                      icon: "el-icon-edit",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return (function () {
                                                          return _vm.handleEdit(
                                                            data
                                                          )
                                                        })($event)
                                                      },
                                                    },
                                                  }),
                                                  _c("el-button", {
                                                    attrs: {
                                                      type: "text",
                                                      icon: "el-icon-delete",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return (function () {
                                                          return _vm.handleDelete(
                                                            data
                                                          )
                                                        })($event)
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                2509609941
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "right-content",
          style: {
            width: _vm.isExpand ? "calc(100% - 324px)" : "calc(100% - 24px)",
          },
        },
        [
          _vm.tableColumnList.length
            ? [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.queryParams,
                      rules: _vm.rules,
                      inline: "",
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.apiInfo.apiShowExchange === 1,
                            expression: "apiInfo.apiShowExchange === 1",
                          },
                        ],
                        attrs: { label: "交易所：" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.queryParams.exchangeIdList,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "exchangeIdList", $$v)
                              },
                              expression: "queryParams.exchangeIdList",
                            },
                          },
                          _vm._l(_vm.exchangeList, function (item) {
                            return _c("el-option", {
                              key: item.exchangeId,
                              attrs: {
                                label: item.exchangeName,
                                value: item.exchangeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.apiInfo.apiDateTitle + "：",
                          rules: [{ required: true }],
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _vm.queryParams.apiDateType === 2
                              ? _c("el-date-picker", {
                                  key: "apiDateType2",
                                  staticStyle: { width: "230px" },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd",
                                    type: "daterange",
                                    "unlink-panels": "",
                                    "range-separator": "至",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    clearable: false,
                                    "picker-options": _vm.datePickerOptions,
                                  },
                                  model: {
                                    value: _vm.queryParams.dateTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "dateTime", $$v)
                                    },
                                    expression: "queryParams.dateTime",
                                  },
                                })
                              : [4].indexOf(_vm.queryParams.apiDateType) > -1
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        multiple: "",
                                        "collapse-tags": "",
                                        placeholder: "请选择年份",
                                      },
                                      model: {
                                        value: _vm.queryParams.year,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryParams, "year", $$v)
                                        },
                                        expression: "queryParams.year",
                                      },
                                    },
                                    _vm._l(_vm.yearList, function (item) {
                                      return _c("el-option", {
                                        key: item,
                                        attrs: { label: item, value: item },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              : [5].indexOf(_vm.queryParams.apiDateType) > -1
                              ? _c("QuarterRangePicker", {
                                  attrs: {
                                    disabledQuarter: _vm.disabledQuarter,
                                    value: _vm.queryParams.reportPeriod,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.queryParams,
                                        "reportPeriod",
                                        $event
                                      )
                                    },
                                  },
                                })
                              : [6].indexOf(_vm.queryParams.apiDateType) > -1
                              ? _c("datePicker", {
                                  key: "apiDateType6",
                                  staticStyle: { width: "230px" },
                                  attrs: {
                                    "value-format": "yyyy-MM",
                                    type: "monthrange",
                                    "unlink-panels": "",
                                    "range-separator": "至",
                                    "start-placeholder": "开始月份",
                                    "end-placeholder": "结束月份",
                                    clearable: false,
                                    rightCurrentTime: _vm.rightCurrentTime,
                                    "picker-options": _vm.monthPickerOptions,
                                  },
                                  model: {
                                    value: _vm.queryParams.monthrange,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "monthrange",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.monthrange",
                                  },
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.apiInfo.apiShowUpdate === 1,
                            expression: "apiInfo.apiShowUpdate === 1",
                          },
                        ],
                        attrs: { label: "变更时间：" },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "403px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetimerange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                          },
                          model: {
                            value: _vm.queryParams.updateDateTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "updateDateTime", $$v)
                            },
                            expression: "queryParams.updateDateTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleSearch },
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              disabled: !_vm.checkPermi([
                                "DataPlatform:DingLiApiV2:Admin",
                                "DataPlatform:DingLiApiV2:Export",
                              ]),
                            },
                            on: { click: _vm.handleExport },
                          },
                          [_vm._v("导出")]
                        ),
                        _vm.apiInfo.hadHelpDoc
                          ? _c(
                              "el-dropdown",
                              {
                                staticStyle: { "margin-left": "20px" },
                                on: { command: _vm.handleCommand },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-question",
                                    }),
                                    _vm._v("帮助指引"),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "view" } },
                                      [_vm._v("在线查看")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "download" } },
                                      [_vm._v("下载指引")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table-area common-table-style" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "table",
                        attrs: {
                          data: _vm.tableData,
                          height: "100%",
                          border: "",
                          stripe: "",
                          "empty-text": "",
                        },
                        on: {
                          "sort-change": _vm.sortChange,
                          "header-dragend": _vm.headerDragend,
                        },
                      },
                      _vm._l(_vm.tableColumnList, function (item) {
                        return _c("el-table-column", {
                          key: _vm.apiInfo.apiTemplateName + item.columnName,
                          attrs: {
                            fixed: item.fixedColumn === 1,
                            width: item.fixedColumn === 1 ? "120px" : "auto",
                            "min-width":
                              item.fixedColumn !== 1 ? "160px" : "auto",
                            prop: item.columnName,
                            sortable: "",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "table-header-title",
                                        attrs: { title: item.headerCn },
                                      },
                                      [_vm._v(_vm._s(item.headerCn))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      1
                    ),
                    !_vm.tableData.length
                      ? _c("div", { staticClass: "empty-text" }, [
                          _vm._v("暂无数据"),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total>0",
                    },
                  ],
                  attrs: {
                    total: _vm.total,
                    page: _vm.queryParams.pageIndex,
                    limit: _vm.queryParams.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.queryParams, "pageIndex", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.queryParams, "pageSize", $event)
                    },
                    pagination: _vm.getTableData,
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "shrinkExpand",
                    on: {
                      click: function ($event) {
                        _vm.isExpand = !_vm.isExpand
                      },
                    },
                  },
                  [
                    _vm.isExpand
                      ? _c("i", { staticClass: "el-icon-arrow-down" })
                      : _c("i", { staticClass: "el-icon-arrow-up" }),
                  ]
                ),
              ]
            : _c("div", { staticClass: "no-data" }, [
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "40px" } },
                  [
                    _c("svg-icon", { attrs: { "icon-class": "no-data" } }),
                    _c("div", { staticClass: "no-text" }, [
                      _vm._v("暂无数据，请先选择指标及范围"),
                    ]),
                  ],
                  1
                ),
              ]),
        ],
        2
      ),
      _vm.secCodeTemplateDialogVisible
        ? _c("secCodeTemplateDialog", {
            attrs: {
              visible: _vm.secCodeTemplateDialogVisible,
              id: _vm.currentTemplateId,
              scope: _vm.queryParams.scope,
            },
            on: {
              saveSuccess: _vm.saveSuccess,
              dialogClose: function ($event) {
                _vm.secCodeTemplateDialogVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }