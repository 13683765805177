<template>
  <div class="dl-databrowser">
    <div class="left-content" :style="{width: isExpand ? '300px' : '0px'}">
      <div v-if="leftHeight>0" :style="{height: leftHeight + 'px'}">
        <div class="card" :style="{ height: topHeight + 'px' }">
          <p class="card-top-title">待选数据集
            <span v-if="currentTemplateNode.templateName" :title="currentTemplateNode.templateName" class="selected-name">
              {{ `${currentTemplateNode.templateName}` }}
            </span>
          </p>
          <div style="flex: 1;height: 0;">
            <TreeComponent
              ref="templateTree"
              style="padding: 12px 10px 10px 10px"
              :showFilterInput="false"
              :treeData="businesstemplateData"
              defaultExpandAll
              :defaultProps="{ children: 'children', label: 'templateName' }"
              @node-click="templateNodeClick"
            >
              <span slot-scope="{ data }" class="node-label">
                <span v-if="data.children && data.children.length" class="parent-node">
                  <svg-icon icon-class="category" />
                  {{ data.templateName }}
                </span>
                <span v-else :title="data.templateName"><svg-icon icon-class="data-table" />
                  {{ data.templateName }}
                </span>
              </span>
            </TreeComponent>
          </div>
        </div>
        <div class="resizer" @mousedown="startResize" ></div>
        <div class="card" :style="{ height: bottomHeight + 'px' }">
          <p class="card-top-title">待选范围
            <span v-if="currentNode.scopeName" :title="currentNode.scopeName" class="selected-name">{{ `${currentNode.scopeName}` }}</span>
          </p>
          <div style="position: relative;margin: 20px 0 12px 20px;">
            <el-select
              v-model="queryParams.selectedSeccode"
              size="small"
              multiple
              filterable
              remote
              reserve-keyword
              collapse-tags
              clearable
              placeholder="请输入股票代码,如:000001"
              popper-class="secCode-select-popper"
              style="width: 220px;"
              :remote-method="remoteMethod"
              @change="selectedSeccodeChange"
              :loading="loading">
              <el-option
                v-for="item in secCodeOptions"
                :key="item.seccode + item.isin"
                :label="item.secname"
                :value="item.seccode">
                <div class="secCode-content">
                  <div>{{item.seccode}}</div>
                  <div class="secname">
                    <span>{{item.secname}}</span>
                    <span v-if="item.delistingDate" class="delisting">退</span>
                  </div>
                </div>
              </el-option>
            </el-select>
            <el-button style="margin-left: 8px" size="small" icon="el-icon-search" @click="handleSearch"></el-button>
            <el-popover v-show="queryParams.selectedSeccode.length > 1" placement="bottom" trigger="hover" popper-class="seccode-popover">
              <div slot="reference" class="popover-div" :style="{left: popoverLeft + 'px', width: popoverWidth + 'px'}"></div>
              <div class="seccode-tag">
                <el-tag v-for="item in queryParams.selectedSeccode.slice(1)" :key="item" closable @close="deleteSeccode(item)">{{ item }}&nbsp;{{selectedSeccodeMap[item]}}</el-tag>
              </div>
            </el-popover>
          </div>
          <div style="flex: 1;height: 0;">
            <TreeComponent
              v-if="scopeTreeData.length"
              ref="scopeTree"
              style="padding: 0px 10px 10px 10px"
              :showFilterInput="false"
              :treeData="scopeTreeData"
              :expandOnClickNode="false"
              :defaultExpandedKeys="defaultExpandedKeys"
              nodeKey="id"
              :defaultProps="{ children: 'children', label: 'scopeName' }"
              @node-click="scopeNodeClick"
            >
              <span slot-scope="{ data }" class="node-content">
                <div v-if="!data.paraCode" class="parent-node" :class="{'disabled-label': setDisabled(data)}">
                  <svg-icon icon-class="row" />
                  {{ data.scopeName }}
                </div>
                <div v-else :title="data.scopeName" class="node-label" :class="{'disabled-label': setDisabled(data)}">
                  <svg-icon icon-class="stock-market" />
                  {{ data.scopeName }}
                </div>
                <i class="el-icon-circle-plus-outline add-icon" v-if="data.id === '1' && !checkPermi(['DataPlatform:DingLiApiV2:LimitAccess'])" @click.stop="addSecCodeTemplate"></i>
                <span v-if="data.parentId === '1'" class="node-btn">
                  <el-button
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="() => handleEdit(data)">
                  </el-button>
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="() => handleDelete(data)">
                  </el-button>
                </span>
              </span>
            </TreeComponent>
          </div>
        </div>
      </div>
    </div>
    <div class="right-content" :style="{width: isExpand ? 'calc(100% - 324px)' : 'calc(100% - 24px)'}">
      <template v-if="tableColumnList.length">
        <el-form :model="queryParams" :rules="rules" inline label-width="100px">
          <el-form-item v-show="apiInfo.apiShowExchange === 1" label="交易所：">
            <el-select v-model="queryParams.exchangeIdList" multiple collapse-tags filterable clearable placeholder="请选择">
              <el-option v-for="item in exchangeList" :label="item.exchangeName" :key="item.exchangeId" :value="item.exchangeId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="apiInfo.apiDateTitle + '：'" :rules="[{required: true}]">
            <div style="display: flex">
              <!-- <div v-if="queryParams.apiDateType === 1">
                <el-checkbox v-model="queryParams.isUseMultipleReports">使用多报告期</el-checkbox>
                <template v-if="queryParams.isUseMultipleReports">
                  <el-date-picker
                    v-model="queryParams.startYear"
                    type="year"
                    value-format="yyyy"
                    style="width: 120px;margin-left: 20px"
                    :picker-options="startPickerOptions"
                    :clearable="false"
                    placeholder="开始年份">
                  </el-date-picker>
                  -
                  <el-date-picker
                    v-model="queryParams.endYear"
                    type="year"
                    value-format="yyyy"
                    style="width: 120px"
                    :clearable="false"
                    :picker-options="endPickerOptions"
                    placeholder="结束年份">
                  </el-date-picker>
                  <el-select v-model="queryParams.reportPeriods" style="margin-left: 20px" multiple placeholder="请选择报告期" key="reportPeriods">
                    <el-option v-for="item in reportPeriodList" :label="item.label" :key="item.value" :value="item.value"></el-option>
                  </el-select>
                </template>
                <template v-else>
                  <el-select v-model="queryParams.yearReportPeriod" style="margin-left: 20px" placeholder="请选择报告期">
                    <el-option v-for="item in yearReportPeriodList" :label="item.label" :key="item.value" :value="item.value"></el-option>
                  </el-select>
                  <template v-if="queryParams.yearReportPeriod === 'custom'">
                    <el-date-picker
                      v-model="queryParams.reportYear"
                      type="year"
                      value-format="yyyy"
                      style="width: 120px;margin-left: 20px"
                      :clearable="false"
                      placeholder="选择年份">
                    </el-date-picker>
                    <el-select v-model="queryParams.reportPeriod" style="margin-left: 20px" placeholder="请选择报告期" key="reportPeriod">
                      <el-option v-for="item in reportPeriodList" :label="item.label" :key="item.value" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </template>
              </div> -->
              <el-date-picker
                v-if="queryParams.apiDateType === 2"
                v-model="queryParams.dateTime"
                key="apiDateType2"
                value-format="yyyy-MM-dd"
                style="width: 230px"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
                :picker-options="datePickerOptions"
              >
              </el-date-picker>
              <template v-else-if="[4].indexOf(queryParams.apiDateType) > -1">
                <el-select v-model="queryParams.year" multiple collapse-tags placeholder="请选择年份">
                  <el-option v-for="item in yearList" :label="item" :key="item" :value="item"></el-option>
                </el-select>
              </template>
              <QuarterRangePicker
                v-else-if="[5].indexOf(queryParams.apiDateType) > -1"
                :disabledQuarter="disabledQuarter"
                :value.sync="queryParams.reportPeriod">
              </QuarterRangePicker>
              <!-- <el-date-picker
                v-else-if="[6].indexOf(queryParams.apiDateType) > -1"
                v-model="queryParams.monthrange"
                key="apiDateType6"
                value-format="yyyy-MM"
                style="width: 230px"
                type="monthrange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                :clearable="false"
                :picker-options="monthPickerOptions"
              >
              </el-date-picker> -->
              <datePicker
                v-else-if="[6].indexOf(queryParams.apiDateType) > -1"
                v-model="queryParams.monthrange"
                key="apiDateType6"
                value-format="yyyy-MM"
                style="width: 230px"
                type="monthrange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                :clearable="false"
                :rightCurrentTime="rightCurrentTime"
                :picker-options="monthPickerOptions">
              </datePicker>
            </div>
          </el-form-item>
          <el-form-item v-show="apiInfo.apiShowUpdate === 1" label="变更时间：">
            <el-date-picker
              v-model="queryParams.updateDateTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 403px"
              type="datetimerange"
              unlink-panels
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
<!--            <el-button size="small" v-hasPermi="['DataPlatform:DingLiApiV2:Admin', 'DataPlatform:DingLiApiV2:Export']" @click="handleExport">导出</el-button>-->
            <el-button size="small" :disabled="!checkPermi(['DataPlatform:DingLiApiV2:Admin', 'DataPlatform:DingLiApiV2:Export'])" @click="handleExport">导出</el-button>
            <el-dropdown v-if="apiInfo.hadHelpDoc" style="margin-left: 20px" @command="handleCommand">
              <span class="el-dropdown-link">
                <i class="el-icon-question"></i>帮助指引<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="view">在线查看</el-dropdown-item>
                <el-dropdown-item command="download">下载指引</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
        <div class="table-area common-table-style">
          <el-table :data="tableData" ref="table" height="100%" border stripe empty-text="" @sort-change="sortChange"  @header-dragend="headerDragend">
            <el-table-column
              v-for="item in tableColumnList"
              :key="apiInfo.apiTemplateName + item.columnName"
              :fixed="item.fixedColumn === 1"
              :width="item.fixedColumn === 1 ? '120px' : 'auto'"
              :min-width="item.fixedColumn !== 1 ? '160px' : 'auto'"
              :prop="item.columnName"
              sortable
              show-overflow-tooltip>
              <template #header>
                <span class="table-header-title" :title="item.headerCn">{{item.headerCn}}</span>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="!tableData.length" class="empty-text">暂无数据</div>
        </div>
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageIndex"
          :limit.sync="queryParams.pageSize"
          @pagination="getTableData"
        />
        <div class="shrinkExpand" @click="isExpand = !isExpand">
          <i v-if="isExpand" class="el-icon-arrow-down"></i>
          <i v-else class="el-icon-arrow-up"></i>
        </div>
      </template>
      <div v-else class="no-data">
        <div style="margin-bottom: 40px">
          <svg-icon icon-class="no-data"></svg-icon>
          <div class="no-text">暂无数据，请先选择指标及范围</div>
        </div>
      </div>
    </div>
    <secCodeTemplateDialog
      v-if="secCodeTemplateDialogVisible"
      :visible="secCodeTemplateDialogVisible"
      :id="currentTemplateId"
      :scope="queryParams.scope"
      @saveSuccess="saveSuccess"
      @dialogClose="secCodeTemplateDialogVisible = false"
    >
    </secCodeTemplateDialog>
  </div>
</template>
<script>
import { loadOpenApiDataByGetFromDataBrowser, loadOpenApiDataByPostFromDataBrowser, downloadHelpDoc, viewHelpDoc } from "@/api/data-platform/admin-center.js";
import { getApiSchemaByApiName, loadSecCodeTemplateList, loadSecCodeCustomList, deleteSecCodeListDef, getCompanySecurityList, getExchangeList, listAuthTemplateTree, listSecCodeChooseScopeTree } from "@/api/data-platform/dl-databrowser.js";
import { formatDateToDay, downloadFile } from "@/utils/index.js";
import secCodeTemplateDialog from "./child/secCodeTemplateDialog.vue";
import TreeComponent from "@/components/TreeComponent";
import QuarterRangePicker from "@/components/QuarterRangePicker";
import datePicker from "@/components/date-picker";
// 分页组件
import pagination from "@/components/Pagination";
import moment from 'moment';
import { reportPeriodList, yearReportPeriodList, dateFilterTypeList } from "@/utils/data-platform/constens.js";
import { getToken } from "@/utils/auth";
import  {checkPermi} from "@/utils/permission";
export default {
  components: {
    pagination,
    secCodeTemplateDialog,
    TreeComponent,
    QuarterRangePicker,
    datePicker
  },
  data () {
    return {
      reportPeriodList: reportPeriodList,
      yearReportPeriodList: yearReportPeriodList,
      dateFilterTypeList: dateFilterTypeList,
      activeName: 'business',
      businesstemplateData: [],
      tableColumnList: [],
      tableData: [],
      apiInfo: {}, // 当前模板信息
      total: 0,
      rules: {
        scope: [{required: true}],
        dateTime: [{required: true}],
      },
      queryParams: {
        scope: '2',
        templateValue: '',
        secCodeList: '',
        selectedSeccode: [],
        apiName: '',
        apiVersion: '',
        pageSize: 20,
        pageIndex: 1,
        dateTime: [],
        effectiveDateStart: '',
        effectiveDateEnd: '',
        updateDateTime: [],
        updateStartTime: '',
        updateEndTime: '',
        isUseMultipleReports: false,
        startYear: (new Date().getFullYear() - 3) + '',
        endYear: new Date().getFullYear() + '',
        reportPeriods: [],
        yearReportPeriod: '@last_Q4@',
        reportYear: new Date().getFullYear() + '',
        reportPeriod: [],
        monthrange: [],
        apiDateType: null,
        orderBy: '',
        orderByDirection: '',
        year: [],
        exchangeIdList: [] // 交易所
      },
      startPickerOptions: {
        disabledDate: (time) => {
          if (this.queryParams.endYear) {
            return time.getTime() > moment(this.queryParams.endYear).valueOf();
          } else {
            return false;
          }
        }
      },
      endPickerOptions: {
        disabledDate: (time) => {
          if (this.queryParams.startYear) {
            return time.getTime() < moment(this.queryParams.startYear).valueOf();
          } else {
            return false;
          }
        }
      },
      // 日期选择设置
      datePickerOptions: {
        disabledDate: (time) => {
          if (this.apiInfo.dataBeginDate) {
            return time.getTime() < moment(this.apiInfo.dataBeginDate).valueOf() || time.getTime() > moment(this.apiInfo.dataEndDate).valueOf();
          }
        }
      },
      // 月份选择设置
      monthPickerOptions: {
        disabledDate: (time) => {
          if (this.apiInfo.dataBeginDate) {
            return time.getTime() < moment(this.apiInfo.dataBeginDate).valueOf() || time.getTime() > moment(this.apiInfo.dataEndDate).valueOf();
          }
        },
        // shortcuts: [
        // {
        //   text: '本月',
        //   onClick(picker) {
        //     picker.$emit('pick', [new Date(), new Date()]);
        //   }
        // },
        // {
        //   text: '今年至今',
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date(new Date().getFullYear(), 0);
        //     picker.$emit('pick', [start, end]);
        //   }
        // },
        // {
        //   text: '过去三个月',
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setMonth(start.getMonth() - 3);
        //     end.setMonth(end.getMonth() - 1);
        //     picker.$emit('pick', [start, end]);
        //   }
        // },
        // {
        //   text: '过去六个月',
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setMonth(start.getMonth() - 6);
        //     end.setMonth(end.getMonth() - 1);
        //     picker.$emit('pick', [start, end]);
        //   }
        // },
        // {
        //   text: '过去一年',
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setMonth(start.getMonth() - 12);
        //     end.setMonth(end.getMonth() - 1);
        //     picker.$emit('pick', [start, end]);
        //   }
        // },
        // {
        //   text: '过去两年',
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setMonth(start.getMonth() - 24);
        //     end.setMonth(end.getMonth() - 1);
        //     picker.$emit('pick', [start, end]);
        //   }
        // }]
      },
      marketClassTemplateList: [],
      sysTemplateList: [],
      customTemplateList: [],
      currentTemplateId: '',
      secCodeTemplateDialogVisible: false,
      secCodeOptions: [],
      selectedSeccodeMap: {}, // 选中的证券代码，代码与名称映射关系
      loading: false,
      isExpand: true, // 是否展开
      popoverLeft: 0,
      popoverWidth: 0,
      fixedList: ['sec_code', 'sec_name', 'exchange_name'],
      yearList: [],
      exchangeList: [],
      apiInfo: {}, // api的信息
      disabledQuarter: [],
      scopeTreeData: [],
      defaultExpandedKeys: [], // 待选范围默认展开节点
      currentNode: {}, // 当前范围节点信息
      currentTemplateNode: {}, // 当前模板节点信息
      leftHeight: 0,
      topHeight: 0, // 初始上部div高度
      bottomHeight: 0, // 初始下部div高度
      startY: 0,
      startTopHeight: 0,
      minBottomHeight: 47,
    }
  },
  created () {
    this.getBusinessTemplateList();
    // this.getSecCodeTemplateList();
    // this.getSecCodeCustomList();
    this.getExchangeList();
    this.getScopeTreeData();
  },
  mounted () {
    this.calcHeight();
    window.onresize = () => {
      this.calcHeight();
    }
  },
  beforeDestroy () {
    window.onresize = null;
  },
  watch: {
  },
  computed: {
    userName () {
      return this.$store.state.user.name;
    },
    templateList () {
      return this.queryParams.scope === '2' ? this.sysTemplateList : this.queryParams.scope === '3' ? this.customTemplateList : this.marketClassTemplateList;
    },
    rightCurrentTime () {
      return this.queryParams.monthrange[0].indexOf('2024') > -1;
    }
  },
  methods: {
    setDisabled (data) {
      // debugger
      return data.disabled;
    },
    checkPermi,
    calcHeight () {
      const dom = document.getElementsByClassName('left-content')[0];
      if (dom) {
        this.leftHeight = dom.clientHeight;
        this.startTopHeight = this.leftHeight / 2;
        this.topHeight = this.startTopHeight;
        this.bottomHeight = this.leftHeight - this.topHeight - 5;
      }
    },
    startResize(event) {
      this.startY = event.clientY;
      this.startTopHeight = this.topHeight;
      document.addEventListener('mousemove', this.onResize);
      document.addEventListener('mouseup', this.stopResize);
    },
    onResize(event) {
      const dy = event.clientY - this.startY;

      let newTopHeight = this.startTopHeight + dy;
      let newBottomHeight = this.leftHeight - newTopHeight - 8;

      // 确保上部div和下部div的高度不小于最小高度
      if (newTopHeight < this.minTopHeight) {
        newTopHeight = this.minTopHeight;
        newBottomHeight = this.leftHeight - newTopHeight - 8;
      } else if (newBottomHeight < this.minBottomHeight) {
        newBottomHeight = this.minBottomHeight;
        newTopHeight = this.leftHeight - newBottomHeight;
      }

      this.topHeight = newTopHeight;
      this.bottomHeight = newBottomHeight;

    },
    stopResize() {
      document.removeEventListener('mousemove', this.onResize);
      document.removeEventListener('mouseup', this.stopResize);
    },
    convertToQuarter(dateStr) {
       const yearMonth = dateStr.split('-');
        const year = yearMonth[0];
        const month = yearMonth[1];
        return `${year}-Q${month}`;
    },
    handleCommand (command) {
      const params = {
        apiName: this.apiInfo.apiName
      }
      if (command === 'download') {
        downloadHelpDoc(params).then((res) => {
          downloadFile(res, this.apiInfo.apiName + '帮助指引.pdf');
        })
      } else {
        let url = '/DingLiApi/ConfigApiUI/viewHelpDoc?' + `apiName=${this.apiInfo.apiName}&Authorization=${getToken()}`
        if (window.location.origin.indexOf('localhost') > -1) {
          url = 'http://localhost:8091' + url;
        } else {
          url = window.location.origin + process.env.VUE_APP_BASE_API + url;
        }
        window.open(url);
      }
    },
    headerDragend () {
      this.$refs.table.doLayout();
    },
    deleteSeccode (code) {
      this.queryParams.selectedSeccode = this.queryParams.selectedSeccode.filter((e) => e !== code);
      this.selectedSeccodeChange();
    },
    selectedSeccodeChange (val) {
      if (val && val.length) {
        this.queryParams.secCodeList = '';
        this.$refs.scopeTree.treeRef.setCurrentKey(null);
        this.currentNode = {};

        if(val.length>20 && checkPermi(['DataPlatform:DingLiApiV2:LimitAccess']))
        {
          this.$modal.msgWarning('您的权限最多只能选20只股票，如需更多请联系系统管理员！');
          return;
        }

        for (const key of val) {
          if (!this.selectedSeccodeMap[key]) {
            const list = this.secCodeOptions.filter((e) => e.seccode === key);
            if (list.length) {
              this.selectedSeccodeMap[key] = list[0].secname;
            }
          }
        }
      }
      this.$nextTick(() => {
        const tagDom = document.querySelectorAll('.el-select__tags .el-tag');
        if (tagDom && tagDom.length > 1) {
          this.popoverLeft = tagDom[0].clientWidth + 14;
          this.popoverWidth = tagDom[1].clientWidth;
        } else {
          this.popoverWidth = 0;
        }
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        getCompanySecurityList({prefix: query}).then((res) => {
          this.secCodeOptions = res.data || [];
          this.loading = false;
        })
      } else {
        this.options = [];
      }
    },

    // 切换数据范围
    scopeChange () {
      this.queryParams.templateValue = '';
    },
    // 添加secCode模板
    addSecCodeTemplate () {
      this.currentTemplateId = '';
      this.secCodeTemplateDialogVisible = true;
    },
    // 编辑secCode模板
    handleEdit (item) {
      this.currentTemplateId = item.id;
      this.secCodeTemplateDialogVisible = true;
    },
    // 删除
    handleDelete (item) {
      this.$modal.confirm('请确认是否删除此模板？', '确定').then(() => {
        deleteSecCodeListDef(item.id).then((res) => {
          if (res.code === 200) {
            if (item.id === this.queryParams.templateValue) {
              this.queryParams.templateValue = '';
            }
            this.$modal.msgSuccess('删除成功');
            this.getScopeTreeData();
          }
        })
      }).catch(function() {
      });
    },
    // 自选范围保存成功
    saveSuccess () {
      this.getScopeTreeData();
    },
    // 查询范围树数据
    getScopeTreeData () {
      listSecCodeChooseScopeTree().then((res) => {
        this.scopeTreeData = res.data || [];
        // debugger
        // console.log(this.scopeTreeData)
        // this.scopeTreeData.forEach((e) => {
        //   e.disabled=true; //设置禁用状态不起作用
        //   console.log(e);
        // })
        this.defaultExpandedKeys = this.scopeTreeData.map((e) => e.id);
      })
    },
    // 查询系统secCode模板
    getSecCodeTemplateList () {
      const params = {
        typeList: [0, 2]
      }
      loadSecCodeTemplateList(params).then((res) => {
        if (res.code === 200 && res.data) {
          this.sysTemplateList = res.data.filter((e) => e.secCodeListType === 0);
          this.marketClassTemplateList = res.data.filter((e) => e.secCodeListType === 2);
        }
      })
    },
    // 查询交易所列表
    getExchangeList () {
      getExchangeList().then((res) => {
        this.exchangeList = res.data || [];
      })
    },
    // 查询自定义secCode模板
    getSecCodeCustomList () {
      loadSecCodeCustomList().then((res) => {
        if (res.code === 200) {
          this.customTemplateList = res.data || [];
        }
      })
    },
    // 查询业务模板
    getBusinessTemplateList () {
      listAuthTemplateTree().then((res) => {
        if (res.code === 200) {
          this.businesstemplateData = res.data || []
        }
      })
    },
    // 初始化查询数据
    initQueryParams () {
      if (this.apiInfo.apiDateType === 2) {
        const currentYear = new Date().getFullYear();
        const startDate = (currentYear - 1) + '-12-31';
        const endDate = formatDateToDay(new Date());
        this.queryParams.dateTime = [startDate, endDate];
      } else if (this.apiInfo.apiDateType === 4) {
        this.yearList = [];
        this.queryParams.year = [];
        let year = new Date().getFullYear();
        let num = year - Number(this.apiInfo.dataBeginDate || 2017);
        for (let i = 0; i <= num; i++) {
          this.yearList.push(Number(this.apiInfo.dataBeginDate || 2017) + i);
        }
        this.queryParams.year.push(year - 1); // 默认选中年份
      } else if (this.apiInfo.apiDateType === 5) {
        // 获取当前日期
        const now = moment();

        // 获取当前季度
        const currentQuarter = now.quarter();

        // 计算前一个季度
        const previousQuarter = currentQuarter - 1;

        // 如果当前是第一季度，前一个季度应该是去年的第四季度
        const year = previousQuarter === 0 ? now.year() - 1 : now.year();
        const quarterToShow = previousQuarter === 0 ? 4 : previousQuarter;
        this.queryParams.reportPeriod = [`${year}-${quarterToShow}`, `${year}-${quarterToShow}`];
      } else if (this.apiInfo.apiDateType === 6) { // 月份选择
        // const date = new Date();
        // debugger
        const date = new moment();
        const dayOfMonth = date.date();
        if(dayOfMonth>10)
        {
          this.queryParams.monthrange = [moment(date).subtract(1, 'months').format('YYYY-MM'), moment(date).subtract(1, 'months').format('YYYY-MM')];
        }else
        {
          this.queryParams.monthrange = [moment(date).subtract(2, 'months').format('YYYY-MM'), moment(date).subtract(2, 'months').format('YYYY-MM')];
        }
      }

      if (this.userName === 'DataViewer') {
        this.queryParams.templateValue = 13;
      }
    },
    // 点击范围节点
    scopeNodeClick (val) {

      if(val.disabled)
      {
        this.$modal.msgWarning('您没有权限，请联系系统管理员！');
        return;
      }

      if (val.paraCode) {
        this.queryParams.secCodeList = val.paraCode;
        this.queryParams.selectedSeccode = [];
        this.currentNode = val;
        if (this.tableColumnList.length) {
          this.handleSearch();
        }
      } else {
        this.$refs.scopeTree.treeRef.setCurrentKey(this.currentNode.id);
      }
    },
    // 点击模板节点
    templateNodeClick (val) {
      if (val.apiName) {
        // Object.assign(this.$data.queryParams, this.$options.data().queryParams); // 初始化查询条件
        const data = val;
        this.currentTemplateNode = val;
        this.queryParams.pageIndex = 1;
        this.total = 0;
        this.tableData = [];

        const params = {
          apiName: data.apiName
        }
        // this.tableColumnList = [];
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        getApiSchemaByApiName(params).then((res) => {
          if (res.code === 200) {
            this.tableColumnList = res.data.columnSchemaVOList || [];
            this.apiInfo = {
              ...res.data.apiMetaEntity,
              dataBeginDate: res.data.dataBeginDate,
              dataEndDate: res.data.dataEndDate,
              hadHelpDoc: res.data.hadHelpDoc,
              templateName: val.templateName
            };
            this.disabledQuarter = [this.apiInfo.dataBeginDate, this.apiInfo.dataEndDate];
            if (this.queryParams.apiDateType !== this.apiInfo.apiDateType) {
              this.initQueryParams();
            }
            this.queryParams.apiDateType = this.apiInfo.apiDateType;
            this.$forceUpdate();
            this.$nextTick(() => {
              this.$refs.table.doLayout();
              if (this.queryParams.selectedSeccode.length || this.queryParams.secCodeList) {
                this.handleSearch();
              } else {
                loading.close();
              }
            })
          }
        }).catch((e) => {
          this.tableColumnList = [];
          loading.close();
        })
      }
    },
    // 表格列排序
    sortChange ({column, prop, order}) {
      this.queryParams.orderBy = prop;
      if (order === 'ascending') {
        this.queryParams.orderByDirection = 'asc';
      } else if (order === 'descending') {
        this.queryParams.orderByDirection = 'desc';
      } else {
        this.queryParams.orderBy = '';
        this.queryParams.orderByDirection = '';
      }
      this.queryParams.pageIndex = 1;
      this.getTableData();
    },
    // 查询
    handleSearch () {
      this.queryParams.pageIndex = 1;
      this.queryParams.orderBy = '';
      this.queryParams.orderByDirection = '';
      this.getTableData();
    },
    // 获取查询参数
    getQueryParams () {
      if (!this.apiInfo.apiName) {
        this.$modal.msgWarning('请先选择待选标的');
        return false;
      }

      if (this.queryParams.selectedSeccode.length === 0 && !this.queryParams.secCodeList) {
        this.$modal.msgWarning('请先选择待选范围');
        return false;
      }

      const params = {
        pageSize: this.queryParams.pageSize,
        pageIndex: this.queryParams.pageIndex,
        apiName: this.apiInfo.apiName,
        apiVersion: this.apiInfo.apiVersion,
        showSqlError: true,
        updateStartTime: this.queryParams.updateDateTime ? this.queryParams.updateDateTime[0] || '' : '',
        updateEndTime: this.queryParams.updateDateTime ? this.queryParams.updateDateTime[1] || '' : '',
        orderBy: this.queryParams.orderBy || undefined,
        orderByDirection: this.queryParams.orderByDirection || undefined,
        exchangeIdList: this.queryParams.exchangeIdList ? this.queryParams.exchangeIdList.join(',') : ''
      }
      // 报告期
      // if (this.queryParams.apiDateType === 1) {
      //   // 多报告期
      //   if (this.queryParams.isUseMultipleReports) {
      //     if (!(this.queryParams.reportPeriods && this.queryParams.reportPeriods.length)) {
      //       this.$modal.msgWarning('请选择报告期');
      //       return false
      //     }
      //     params.effectiveDateRangeList = `@${this.queryParams.startYear}~${this.queryParams.endYear}_${this.queryParams.reportPeriods.join('~')}@`
      //   } else {
      //     // 单报告期
      //     if (this.queryParams.yearReportPeriod === 'custom') {
      //       if (!this.queryParams.reportPeriod) {
      //         this.$modal.msgWarning('请选择报告期');
      //         return false
      //       }
      //       params.effectiveDateRangeList = `@${this.queryParams.reportYear}_${this.queryParams.reportPeriod}@`
      //     } else {
      //       params.effectiveDateRangeList = this.queryParams.yearReportPeriod;
      //     }
      //   }
      // } else
      if (this.queryParams.apiDateType === 2) {
        // 日期范围
        params.effectiveDateStart = this.queryParams.dateTime ? this.queryParams.dateTime[0] : '';
        params.effectiveDateEnd = this.queryParams.dateTime ? this.queryParams.dateTime[1] : '';
      } else if ([4].indexOf(this.queryParams.apiDateType) > -1) { // 年份选择
        if (!(this.queryParams.year && this.queryParams.year.length)) {
          this.$modal.msgWarning('请选择需要查询的年份');
          return false
        }
        params.effectiveDateRangeList = `@${this.queryParams.year.join('~')}@`;
      } else if ([5].indexOf(this.queryParams.apiDateType) > -1) { // 季度选择
        if (!(this.queryParams.reportPeriod && this.queryParams.reportPeriod.length)) {
          this.$modal.msgWarning('请选择需要查询的季度');
          return false
        }
        params.effectiveDateStart = this.convertToQuarter(this.queryParams.reportPeriod[0]);
        params.effectiveDateEnd = this.convertToQuarter(this.queryParams.reportPeriod[1]);
      } else if ([6].indexOf(this.queryParams.apiDateType) > -1) { // 月份选择
        if (!(this.queryParams.monthrange && this.queryParams.monthrange.length)) {
          this.$modal.msgWarning('请选择需要查询的月份');
          return false
        }
        // 月份范围
        params.effectiveDateStart = this.queryParams.monthrange ? this.queryParams.monthrange[0] + '-01': '';
        params.effectiveDateEnd = this.queryParams.monthrange ? moment(this.queryParams.monthrange[1]).endOf("month").format("YYYY-MM-DD") : '';
      }
      if (this.queryParams.selectedSeccode.length) {
        params.secCodeList = 'IP_' + this.queryParams.selectedSeccode.join(',');
      } else {
        params.secCodeList = this.queryParams.secCodeList;
      }
      return params
    },
    // 查询表格数据
    getTableData () {
      const params = this.getQueryParams();
      if (!params) {
        return;
      }

      const requestMethod = this.apiInfo.apiRequestMethod === 'Post' ? loadOpenApiDataByPostFromDataBrowser: loadOpenApiDataByGetFromDataBrowser;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      //需要限制的账号最多只能查20个公司
      if(params.secCodeList.split(",").length>20 && checkPermi(['DataPlatform:DingLiApiV2:LimitAccess']))
      {
        loading.close();
        this.$modal.msgWarning('您的权限最多只能选20只股票，如需更多请联系系统管理员！');
        return;
      }

      requestMethod(params).then((res) => {
        loading.close();
        if (res.code === 200) {
          this.tableData = res.data.records || [];
          this.total = res.data.total;
        }
      }).catch((e) => { loading.close(); })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQueryParams();
      if (!params) {
        return;
      }
      params.templateName = this.apiInfo.templateName;
      this.download('DingLiApi/DataBrowser/exportApiDataFromDataBrowser', {
        ...params
      }, `${params.templateName}_${new Date().getTime()}.xlsx`)
    }
  }
}
</script>
<style lang="scss" scoped>
.disabled-label{color: grey}
.dl-databrowser {
  padding: 20px;
  height: 100%;
  .left-content {
    width: 300px;
    float: left;
    overflow: hidden;
    height: 100%;
    position: relative;
    transition: width .3s ease;
    color: #01579b;
    display: flex;
    flex-direction: column;
    .resizer {
      height: 8px;
      cursor: row-resize; /* 设置光标样式 */
      position: relative;
      z-index: 1;
    }
    .card {
      // flex: 1;
      // height: 0;
      min-height: 40px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
    }
    .card-top-title {
      border-radius: 6px 6px 0 0;
      line-height: 40px;
      padding-left: 10px;
      font-weight: 600;
      background-color: #dfebf9;
      font-size: 14px;
      .selected-name {
        display: inline-block;
        width: calc(100% - 120px);
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
        margin-right: 20px;
        text-align: right;
        float: right;
        font-weight: 500;
      }
    }
    .parent-node {
      font-weight: 700;
    }
    .node-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
    }
    .node-label {
      flex: 1;
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .add-icon {
      cursor: pointer;
      color: #1890ff;
      display: none;
      font-size: 16px;
    }
    .node-btn {
      display: none;
    }
    .el-tree-node__content {
      &:hover {
        .node-btn {
          display: block;
        }
        .add-icon {
          display: block;
        }
      }
    }
  }
  .shrinkExpand {
    position: absolute;
    left: -42px;
    top: calc(50% - 25px);
    width: 60px;
    height: 0;
    display: flex;
    justify-content: center;
    border-bottom: 18px solid #fff;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-radius: 4px;
    transform: translateY(calc(-50% + 27px)) rotate(90deg);
    cursor: pointer;
    filter: drop-shadow(0px -3px 3px rgba(0,0,0,.1));
    i {
      color: #01579b;
      font-weight: 500;
    }
  }
  .right-content {
    background-color: #FFFFFF;
    width: calc(100% - 324px);
    height: 100%;
    float: right;
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    transition: width .3s ease;
    .no-data {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .svg-icon {
        width: 400px;
        height: 400px;
      }
      .no-text {
        color: #909399;
        text-align: center;
        font-size: 14px;
      }
    }
    .table-area {
      height: 0;
      flex: 1;
      position: relative;
    }
    ::v-deep .el-table__empty-text {
      display: none;
    }
    .empty-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      line-height: 60px;
      color: #909399;
    }
  }
  .el-tabs {
    height: 100%;
    ::v-deep .el-tabs__content {
      height: calc(100% - 55px);
      .el-tab-pane {
        height: 100%;
      }
    }
    ::v-deep .el-tabs__item.is-active {
      color: #01579b;
      font-weight: 600;
    }
  }
  .popover-div {
    position: absolute;
    height: 24px;
    top: 6px;
    z-index: 1000;
  }
  .table-header-title {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;  /** 如果设置为 2 则表示只显示2行 */
    -webkit-box-orient: vertical;
    max-width: calc(100% - 20px);
  }
  ::v-deep .el-table--border .el-table__cell {
    border-right: 0 !important;
  }
  ::v-deep .el-table th.el-table__cell > .cell {
    display: flex;
    align-items: center;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-dropdown-link {
    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
}
</style>
<style lang="scss">
.template-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.secCode-select-popper {
  .secCode-content {
    display: flex;
    justify-content: space-between;
    .secname {
      display: flex;
      align-items: center;
      .delisting {
        background-color: red;
        color: #FFFFFF;
        padding: 0 2px;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        margin-left: 2px;
      }
    }
  }
  .el-select-dropdown__item {
    padding-right: 20px !important;
  }
}
.seccode-popover {
  .seccode-tag {
    max-width: 600px;
    display: flex;
    flex-flow: wrap;
    gap: 10px;
  }
}

</style>
